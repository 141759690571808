/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object,
    func,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import ReactMarkdown from 'react-markdown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import { ifExternalUrl } from './helper';
import cleanText from '../../../helpers/contentstack/cleanText';
import transformRenderer from '../../../helpers/markdown/characterTransformer';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const screenWidths = {
    // mui down is exclusive (-0.05px); mui up inclusive
    mb: {
        max: 600,
    },
    tablet: {
        min: 600,
        max: 900,
    },
    DTsm: {
        min: 900,
        max: 1024,
    },
    DT: {
        min: 1024,
    },
};

const useStyles = makeStyles((theme) => ({
    container: ({ copyColor }) => ({
        display: 'grid',
        columnGap: '1rem',
        rowGap: '0.5rem',
        maxWidth: '1400px',
        margin: '0 auto',
        '& a': {
            display: 'inherit',
            fontSize: '1rem',
            fontWeight: '400',
            textDecoration: 'none',
            color: copyColor || '#2F2F2F',
        },
        [theme.breakpoints.between(screenWidths.tablet.min, screenWidths.tablet.max)]: {
            columnGap: '10px',
        },
        [theme.breakpoints.down(screenWidths.mb.max)]: {
            margin: '0 10px',
        },
    }),
    // 1440, 1024, tablet have 24 columns in grid
    // mb has 6 grid columns
    fourPanelContainer: {
        gridTemplateColumns: '6fr 6fr 6fr 6fr',
        gridTemplateRows: '1fr',
        [theme.breakpoints.down(768)]: {
            gridTemplateColumns: '3fr 3fr',
        },
    },
    threePanelContainer: {
        gridTemplateColumns: '8fr 8fr 8fr',
        gridTemplateRows: '1fr',
        [theme.breakpoints.down(768)]: {
            gridTemplateColumns: '6fr',
        },
    },
    twoPanelContainer: {
        gridTemplateColumns: '12fr 12fr',
    },
    panel: ({ brand, panelBgColor }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1rem',
        backgroundColor: panelBgColor || '#E8F1ED',
        '& h3': {
            marginTop: 0,
            marginBottom: 0,
            lineHeight: '2rem',
            fontWeight: 400,
            fontFamily: theme?.typography?.heading?.fontFamily || 'Serif',
            fontSize: theme?.typography?.heading?.fontSize?.desktop || '1.75rem',
            textTransform: brand.code === 'CCO' ? 'uppercase' : 'none',
        },
        '& p': {
            marginTop: '10px',
            marginBottom: '10px',
        },
        '& icon': {
            lineHeight: 0,
        },
        [theme.breakpoints.between(screenWidths.tablet.min, screenWidths.tablet.max)]: {
            padding: '10px',
        },
        [theme.breakpoints.down(screenWidths.mb.max)]: {
            padding: '.75rem',
        },
        [theme.breakpoints.down(screenWidths.tablet.max)]: {
            '& h3': {
                marginBottom: '10px',
            },
        },
        [theme.breakpoints.down(screenWidths.tablet.max)]: {
            '& h3': {
                fontSize: theme?.typography?.heading?.fontSize?.mobile || '1.25rem',
                lineHeight: '1.65rem',
            },
            '& p': {
                display: 'none',
            },
        },
    }),
    fourUpPanel: {
        '& icon': {
            alignSelf: 'flex-end',
        },
    },
    pgraph: {
        opacity: '.95',
    },
    headingRow: {
        '& $cta': {
            marginTop: '10px',
        },
        [theme.breakpoints.down(screenWidths.tablet.max)]: {
            pgraph: {
                display: 'none',
            },
        },
    },
    [theme.breakpoints.down(screenWidths.tablet.max)]: {
        pgraph: {
            display: 'none',
        },
    },
    ctaRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cta: {
        fontFamily: theme?.typography?.cta?.fontFamily || 'latobold, Arial, Verdana, sans-serif',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        textTransform: 'uppercase',
        fontSize: theme?.typography?.cta?.fontSize?.sm,
        [theme.breakpoints.down(screenWidths.tablet.max)]: {
            fontSize: theme?.typography?.cta?.fontSize?.sm,
        },
    },
    icon: {
        lineHeight: 0,
        alignSelf: 'end',
        '& img': {
            width: '59px',
            height: '59px',
        },
        [theme.breakpoints.down(screenWidths.DTsm.max)]: {
            '& img': {
                width: '44px',
                height: '44px',
            },
        },
    },
}));

const CalloutPanels = ({ data, track }) => {
    const brand = useSelector(getBrand); // maybe is in state. don't need to call again?  Check on wf
    const panelBgColor = data.reference[0].panel_background_color?.color;
    const copyColor = data.reference[0].copy_color?.color;
    const classes = useStyles({ brand, panelBgColor, copyColor });
    const panelsData = data.reference[0]?.panels_group;

    if (panelsData) {
        const gridContainerClasses = { // key is number of columns
            2: classes.twoPanelContainer,
            3: classes.threePanelContainer,
            4: classes.fourPanelContainer,
        };

        const gridClasses = {
            2: classes.twoUpPanel,
            3: classes.threeUpPanel,
            4: classes.fourUpPanel,
        };
        const gridClass = gridClasses[panelsData.length];

        const DesktopMarkup = (panels) => (
            panels.map((panel) => (
                <Link
                    to={panel.linking?.link?.href}
                    title={panel.linking?.link?.title}
                    data-ga-category={panel.linking?.tracking_event_category || ''}
                    data-ga-action={panel.linking?.tracking_event_action || ''}
                    data-ga-label={panel.linking?.tracking_event_label || ''}
                    className={classes.link}
                    aria-hidden="true"
                    tabIndex="-1"
                    onClick={(e) => {
                        track({
                            eventCategory: panel.linking?.tracking_event_category || '',
                            eventAction: panel.linking?.tracking_event_action || '',
                            eventLabel: panel.linking?.tracking_event_label || '',
                        });
                        ifExternalUrl(e, panel.linking?.link?.href);
                    }}
                >
                    <div className={`${classes.panel} ${gridClass}`}>
                        <div className={classes.headingRow}>
                            <h3>{panel.heading}</h3>
                            <div className={classes.pgraph}>
                                <ReactMarkdown renderers={transformRenderer} source={cleanText(panel.paragraph)} escapeHtml={false} />
                            </div>
                        </div>
                        <div className={classes.ctaRow}>
                            <div className={classes.cta}>{panel.cta} <ChevronRightIcon /></div>
                            <div className={classes.icon}>
                                <img src={panel.icon.url} alt={panel.icon.filename} />
                            </div>
                        </div>
                    </div>
                </Link>
            ))
        );

        const MobileMarkup = (panels) => (
            panels.map((panel) => (
                <Link
                    to={panel.linking?.link?.href}
                    title={panel.linking?.link?.title}
                    data-ga-category={panel.linking?.tracking_event_category || ''}
                    data-ga-action={panel.linking?.tracking_event_action || ''}
                    data-ga-label={panel.linking?.tracking_event_label || ''}
                    className={classes.link}
                    aria-hidden="true"
                    tabIndex="-1"
                    onClick={(e) => {
                        track({
                            eventCategory: panel.linking?.tracking_event_category || '',
                            eventAction: panel.linking?.tracking_event_action || '',
                            eventLabel: panel.linking?.tracking_event_label || '',
                        });
                        ifExternalUrl(e, panel.linking?.link?.href);
                    }}
                >
                    <div className={`${classes.panel} ${gridClass}`} tabIndex="-1">
                        <div className={classes.headingRow}>
                            <h3>{panel.heading}</h3>
                            <div className={classes.cta}>{panel.cta} <ChevronRightIcon /></div>
                        </div>
                        <div className={classes.icon}>
                            <img src={panel.icon.url} alt={panel.icon.filename} />
                        </div>
                    </div>
                </Link>
            ))
        );

        return (
            <section className={`${classes.container} ${gridContainerClasses[panelsData.length]}`}>
                <DesktopMobile
                    desktop={() => DesktopMarkup(panelsData)}
                    mobile={() => {
                        if (panelsData.length === 3) return DesktopMarkup(panelsData); // CTA should stay on same row as icon, hence using dt markup
                        return MobileMarkup(panelsData);
                    }}
                    breakpoint={899.95}
                />
            </section>
        );
    }
    return <></>;
};

CalloutPanels.propTypes = {
    data: object.isRequired,
    track: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

const enhance = compose(
    connect(null, mapDispatchToProps),
);

export default enhance(CalloutPanels);
